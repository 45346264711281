<template>
    <admin-dashboard-layout>
        <router-link
            :to="{name: 'admin.faq_sections.add'}"
        >
            <v-btn
                color="black"
                large
            >
                <v-icon color="green">mdi-plus-outline</v-icon>
                <span>{{ $t('Add FAQ Section Item') }}</span>
            </v-btn>
        </router-link>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="faq_sections"
            :items-per-page="30"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at ? moment(item.created_at).format('LLL') : '' }}
            </template>
            <template v-slot:item.action="{ item }">
                <v-btn @click.stop="handleDeleteItem(item.id)">
                    <v-icon color="red">mdi-trash-can-outline</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import FaqSections from "@/models/admin/FaqSections";

export default {
    name: "faq-sections-index",
    components: {
        AdminDashboardLayout,
    },
    data: function () {
        return {
            loading: false,
            faq_sections: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Section Group Name', value: 'section_group_name'},
                {text: 'Section Title', value: 'section_title'},
                {text: 'Section Order Group', value: 'order_group'},
                {text: 'Section Order', value: 'order'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Action', value: 'action'},
            ],
        }
    },
    methods: {
        handleClickRow(word) {
            this.$router.push({name: 'admin.faq_sections.show', params: {id: word.id}})
        },
        handleDeleteItem(id) {
            FaqSections.$http.delete(`/api/admin/faq-sections/${id}`).then(() => {
                this.$store.commit('showSnackBar', this.$t('messages.success'))
                FaqSections.get().then((res) => {
                    this.faq_sections = res
                })
            })
        },
    },
    async mounted() {
        this.loading = true
        this.faq_sections = await FaqSections.get()
        this.loading = false
    },
}
</script>

<style scoped>

</style>
